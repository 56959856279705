<template>
  <van-pull-refresh v-model="isLoading" pulling-text="Tarik ke bawah untuk refresh..."
    loosing-text="Lepaskan untuk refresh..." success-text="Refresh Berhasil" @refresh="onRefresh">
    <BaseHeaderBar bgColor="transparent" :showBackButton="true" />
    <div v-if="orderStatus === 1 || orderStatus === 2 || orderStatus === 10" class="loan-status1">
      <img class="top-img" src="@/assets/img/loan-status1.png" />
      <p class="intro">Dalam proses peninjauan</p>
    </div>

    <div v-else-if="orderStatus === 3" class="loan-status3">
      <img class="top-img" src="@/assets/img/loan-status3.png" />
      <p style="font-size: 16px; margin: 15px; color: #666">
        Pelunasan tepat waktu bisa meningkatkan limit pinjaman
      </p>
      <p class="intro">Dalam proses pencairan</p>
      <div class="info">
        <div class="left-block">
          <div class="circle" v-for="n in 3" :key="n"></div>
        </div>
        <div class="right-block">
          <div>
            <p class="p1">Jumlah yang diterima</p>
            <p class="p2">
              Rp{{ formatNumberWithCommas(loanInfo.ardrrivalAmount) }}
            </p>
          </div>
          <div>
            <p class="p1">Nama bank penerima</p>
            <p class="p2">{{ loanInfo.ardrrivalBank }}</p>
          </div>
          <div>
            <p class="p1">Nomor rekening bank penerima</p>
            <p class="p2">{{ loanInfo.ardrrivalBankcard }}</p>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="orderStatus === 4" class="loan-status4">
      <img class="top-img" src="@/assets/img/loan-status4.png" />
      <div class="desc">
        <p class="intro">Anda tidak dapat melakukan pinjaman saat ini</p>
        <p class="detail">
          Untuk saat ini Anda tidak memenuhi persyaratan. Pastikan untuk selalu
          mempertahankan riwayat kredit yang baik.
        </p>
      </div>

      <a href="https://apps.apple.com/id/app/tunai-cash-pinjaman-online/id6739834547?l=id">
        <img class="daichao" src="@/assets/img/daichao1.png" alt="" />
      </a>

      <van-dialog class="tuijian_chanpin" v-model="visible" :showConfirmButton="false" close-on-click-overlay>
        <template #title>
          <p class="tuijian_title">Menyarankan</p>
        </template>
        <div class="tuijian_neirong">
          <img src="@/assets/img/tuijianchapin.png" alt="" />
          <div class="chanpin_name">Tunai Cash Pinjaman Online</div>
          <div class="youdian">
            <span>Rp 9.000.000</span>
          </div>
          <div class="youdian hari">
            <span>92-180 hari</span>
          </div>
          <div @click="onCloseDialog()">
            <a href="https://apps.apple.com/id/app/tunai-cash-pinjaman-online/id6739834547?l=id">
              <button type="button" class="submit-btn OKE">OKE</button>
            </a>
          </div>
        </div>
      </van-dialog>
    </div>

    <div v-else-if="orderStatus === 5" class="loan-status5">
      <div class="info_box">
        <div class="top-block">
          <p class="p1">Menunggu Pembayaran</p>
          <p class="p2">
            Harap pembayaran sebelum tanggal
            {{ loanInfo.bidrllDetails.redrpaymentTime.substr(0, 2) }} bulan
            {{ loanInfo.bidrllDetails.redrpaymentTime.substr(3, 2) }}
            tahun {{ loanInfo.bidrllDetails.redrpaymentTime.substr(6) }}
          </p>
        </div>
        <div class="detail-block">
          <div class="overdue">
            Sudah terlambat {{ loanInfo.bidrllDetails.ovdrerdueDay }} hari
          </div>
          <img class="fenge_icon" src="@/assets/img/fenge_icon.png" alt="" />

          <p class="full-label">
            Nominal Pelunasan:Rp{{
              formatNumberWithCommas(
                loanInfo.bidrllDetails.fudrllrepaymentAmount
              )
            }}
          </p>
          <p class="installment-label">
            Nominal Pembayaran Perpanjangan:Rp{{
              formatNumberWithCommas(
                loanInfo.bidrllDetails.exdrhibitionrepaymentaAount
              )
            }}
          </p>
          <div class="detail" @click="goRepaymenDetail">Detail Pinjaman</div>
        </div>
      </div>

      <button type="button" class="full-repayment-btn" @click="goRepay(1)">
        Pelunasan
      </button>
      <button type="button" class="installment-repayment-btn" @click="goRepay(2)">
        Perpanjangan
      </button>
      <a href="https://www.instagram.com/bukudana_pinjaman?igsh=YjJ3bDJocjNxZm9m&utm_source=qr">
        <img src="@/assets/img/Instagram.png" style="width: 100%; margin-top: 10px" alt="" />
      </a>
      <Discount :money="loanInfo.didrscountAmount"></Discount>
      <Recommendation v-if="loanInfo.redr_loan == 1" :redrpaymentTime="loanInfo.bidrllDetails.redrpaymentTime"
        :orderStatus="orderStatus"></Recommendation>
    </div>

    <div v-else-if="orderStatus === 0 || orderStatus === 6 || orderStatus === 7" class="loan-status6">
      <div class="status6_box">
        <div class="jiekuanjine">
          <div class="jine">
            <p>Nominal Pinjaman</p>
            <div class="amount">Rp{{ formatNumberWithCommas(loanAmount) }}</div>
          </div>
        </div>
        <div class="jine_list">
          <div v-for="(item, index) in loanInfo.mudrltipleLoanList" :key="index">
            <div :class="[
              'status6_circle',
              item.loanStatus ? 'status6_circle1' : '',
              { active: activeIndex === index },
            ]" @click="selectLoan(item, index)">
              Rp{{ formatNumberWithCommas(item.loanMoney) }}
            </div>
          </div>
        </div>
        <div class="jeikaunqixian" v-if="loanTimers && loanTimers.length">
          <p class="p1">Tenor Pinjaman</p>
          <p class="p2">{{ loanTimers[0] }} Hari</p>
        </div>
        <div class="ticket" v-if="orderStatus === 6 || orderStatus === 7">
          <img src="@/assets/img/ticket.png" alt="" />
          <span>{{
            loanInfo.mudrltipleLoanList[activeIndex].discountAmount
          }}</span>
          <div></div>
        </div>
        <button type="button" @click="submit">Segera Pengajuan</button>
        <a v-if="orderStatus === 0 || orderStatus === 6"
          href="https://www.instagram.com/bukudana_pinjaman?igsh=YjJ3bDJocjNxZm9m&utm_source=qr">
          <img src="@/assets/img/Instagram.png" style="width: 100%; margin-top: 10px" alt="" />
        </a>
      </div>

      <van-dialog v-model="visible" :showConfirmButton="false" close-on-click-overlay>
        <template #title>
          <p class="rise-limit-title">Saya ingin menaikkan limit</p>
        </template>
        <h3 class="tip-title">Bagaimana cara menaikkan limit pinjaman</h3>
        <p class="tip">
          Mempertahankan kebiasaan baik dan selalu melunasi tepat waktu. Anda
          bisa mendapatkan kenaikan limit apabila menghindari perilaku
          keterlambatan pembayaran.
        </p>
        <button type="button" class="confirm-btn" @click="onCloseDialog()">
          Mengerti
        </button>
      </van-dialog>
      <Recommendation :orderStatus="orderStatus"></Recommendation>
    </div>

    <div v-else-if="orderStatus === 8" class="loan-status8">
      <img class="top-img" src="@/assets/img/loan-status8.png" />
      <p class="intro">Verifikasi KTP gagal</p>
      <p class="detail">
        Foto KTP yang Anda unggah tidak jelas, sehingga tidak dapat lolos
        evaluasi. Silakan unggah ulang.
      </p>
      <button type="button" class="modify-btn" @click="goAuthentication">
        Edit data
      </button>
    </div>

    <div v-else-if="orderStatus === 9" class="loan-status9">
      <img class="top-img" src="@/assets/img/loan-status9.png" />
      <div class="desc">
        <p class="intro">Pencairan gagal</p>
        <p class="detail">
          Pencairan tidak dapat dilakukan karena data rekening <br />
          bank Anda tidak tersedia. Silakan isi ulang.
        </p>
      </div>
      <button type="button" class="modify-btn" @click="goBankInfo">
        Edit data
      </button>
      <van-dialog v-model="cancelLoanVisible" :showConfirmButton="false" close-on-click-overlay
        class="cancel-loan-dialog">
        <img class="cancel-loan-img" src="@/assets/img/loan-status7.png" />
        <h3 class="cancel-loan-dialog-title">Berhasil dibatalkan</h3>
        <p class="tip">
          Pinjaman Anda telah dibatalkan. Anda tidak perlu verifikasi data lagi
          jika ingin melakukan pinjaman.
        </p>
        <button type="button" class="confirm-btn" @click="onCloseDialog('cancelLoanVisible')">
          Mengerti
        </button>
      </van-dialog>
    </div>

    <div v-else-if="orderStatus === 12" class="loan-status12">
      <p class="deadline-title">Nominal Pinjaman</p>
      <div class="time">
        <!-- <img class="lock-icon" src="@/assets/img/lock-icon.png" /> -->
        <p>Rp{{ formatNumberWithCommas(loanInfo.lodranAmount) }}</p>
      </div>
      <template v-if="loanInfo.avdrailablePeriod && loanInfo.avdrailablePeriod.length">
        <p class="deadline-title">
          pinjaman {{ loanInfo.avdrailablePeriod[0] }} Hari
        </p>
        <div class="time">
          <img class="deadline-icon" src="@/assets/img/deadline-icon.png" />
          <p>{{ loanInfo.avdrailablePeriod[0] }} Hari</p>
        </div>
      </template>
      <button type="button" class="withdraw-btn" @click="withdraw">
        Segera Tarik
      </button>
    </div>

    <van-dialog v-model="feeVisible" :showConfirmButton="false" close-on-click-overlay>
      <template #title>
        <p class="fee-title">Rincian Biaya</p>
      </template>
      <div class="fee-detail">
        <div>
          <p>Nominal Pinjaman</p>
          <p>Rp{{ formatNumberWithCommas(feeDetail.lodranCash) }}</p>
        </div>
        <div>
          <p>Jumlah Hari Pinjaman</p>
          <p>{{ feeDetail.lodranTimes }} Hari</p>
        </div>
        <div class="interest">
          <p>Total Bunga</p>
          <p>
            <span>{{ feeDetail.ordriginalAmount }}</span>
            <span>Rp{{ formatNumberWithCommas(feeDetail.todrtalCost) }}</span>
          </p>
          <div class="describe">
            Voucher pengurangan bunga telah otomatis digunakan (peningkatan
            jumlah pinjaman) {{ feeDetail.didrscountAmount }}
          </div>
        </div>
        <div>
          <p>Jumlah yang diterima</p>
          <p>Rp{{ formatNumberWithCommas(feeDetail.ardrrivalCash) }}</p>
        </div>
      </div>
      <div class="footer-btn">
        <button type="button" class="cancel-btn" @click="onCloseDialog('feeVisible')">
          Batal
        </button>
        <button type="button" class="ok-btn" @click="onOkFee">
          Konfirmasi
        </button>
      </div>
    </van-dialog>
    <template #loading>
      <span></span>
    </template>
  </van-pull-refresh>
</template>

<script>
import Vue from 'vue'
import { Dialog, Toast, PullRefresh } from 'vant'
import request from '@/utils/request'
import dialogVisible from '@/mixins/dialogVisible'
import webviewBack from '@/mixins/webviewBack'
import { getMetadata } from '@/utils/getEquipmentInfo.js'
import Discount from '@/components/discount.vue'
import Recommendation from '@/components/Recommendation.vue'

Vue.use(Toast)
Vue.use(PullRefresh)

export default {
  name: 'Home',
  mixins: [dialogVisible, webviewBack],
  components: {
    [Dialog.Component.name]: Dialog.Component,
    Discount,
    Recommendation
  },
  data () {
    return {
      isLoading: false,
      cancelLoanVisible: false,
      feeVisible: false,
      orderStatus: '',
      loanInfo: {},
      feeDetail: {},
      activeIndex: -1,
      loanTimers: [],
      loanAmount: ''
    }
  },
  created () {
    this.getOrderStatus()
  },
  methods: {
    getOrderStatus (isRefresh) {
      request.post('homeDRInterface')
        .then(res => {
          this.orderStatus = res.ordrderStatus
          this.loanInfo = res
          // this.orderStatus = 6
          // this.loanInfo = {
          //   bidrllDetails: {
          //     redrpaymentTime: ''
          //   }
          // }
          if (this.orderStatus === 4) {
            if (!localStorage.getItem('tuijian_product')) {
              this.visible = true
            }
            localStorage.setItem('tuijian_product', true)
          }
          if (res.ordrderStatus === 0 || res.ordrderStatus === 6 || res.ordrderStatus === 7) {
            this.loanAmount = res.lodranMaxAmount
            let index = -1
            localStorage.removeItem('showTicket')

            // 过滤掉不能借的金额
            // if (res.ordrderStatus === 6 || (res.ordrderStatus === 7 && res.redr_loan === 1)) {
            //   this.loanInfo.mudrltipleLoanList = res.mudrltipleLoanList.filter((item) => {
            //     return item.loanStatus
            //   })
            // }
            res.mudrltipleLoanList.forEach((item, i) => {
              if (item.loanStatus) {
                index = i
              }
            })
            if (index > -1) {
              this.activeIndex = index
              this.selectedLoanItem = res.mudrltipleLoanList[index]
              this.loanAmount = this.selectedLoanItem.loanMoney
              this.loanTimers = this.initLoanTimers = this.selectedLoanItem.loanTimers || []
            }
          }
          isRefresh && (this.isLoading = false)
        })
    },
    getFeeDetail (item, loanType) {
      const postData = {
        lodranCash: item.loanMoney,
        lodranTimers: item.loanTimers[0],
        lodranType: loanType
      }
      this.withdrawType = loanType
      request.post('indrterestrateBomb', postData)
        .then(res => {
          this.feeDetail = res
        })
    },
    goRepaymenDetail () {
      this.$router.push('/repaymenDetail')
    },
    goBankInfo () {
      if (this.loanInfo.badrnkStatus) {
        const postData = {
          apdrplicationAmount: this.loanInfo.lodranAmount,
          prdromotionChannel: 'AppStore',
          portraitAdd: 1
        }
        // 获取设备信息
        getMetadata()
          .then(res => {
            // 提交设备信息
            return request.post('deviceInformation', res)
          })
          .then(() => {
            // 提交订单
            return request.post('sudrbmitloanOrder', postData)
          })
          .then(() => {
            this.getOrderStatus()
          })
      } else {
        this.goAuthentication()
      }
    },
    goRepay (repayType) {
      const { ordrderID, padrymentID, fudrllrepaymentAmount, exdrhibitionrepaymentaAount } = this.loanInfo.bidrllDetails
      this.$router.push({
        path: '/repay',
        query: {
          repayType,
          amount: repayType === 1 ? fudrllrepaymentAmount : exdrhibitionrepaymentaAount,
          orderId: ordrderID,
          payID: padrymentID
        }
      })
    },
    selectLoan (item, index) {
      const loanStatus = item.loanStatus
      if (loanStatus) {
        if (index !== this.activeIndex) {
          this.activeIndex = index
          this.selectedLoanItem = item
          this.loanAmount = item.loanMoney
          this.loanTimers = item.loanTimers
        }
      } else {
        this.visible = true
      }
    },
    submit () {
      if (this.selectedLoanItem) {
        if (this.orderStatus === 0 || (this.orderStatus === 7 && this.loanInfo.redr_loan === 0)) {
          // if (this.orderStatus === 0) {
          //   try {
          //     window.publishFBRecordPM()
          //   } catch (e) {
          //     console.log(e)
          //   }
          // }
          this.$router.push({
            path: '/authentication',
            query: {
              amount: this.selectedLoanItem.loanMoney
            }
          })
        } else if (this.orderStatus === 6 || (this.orderStatus === 7 && this.loanInfo.redr_loan === 1)) {
          this.getFeeDetail(this.selectedLoanItem, 2)
          this.feeVisible = true
        }
      } else {
        Toast('Silahkan pilih pinjaman terlebih dahulu')
      }
    },
    onOkFee () {
      if (this.flag) return
      this.flag = true
      const { lodranCash, lodranTimes } = this.feeDetail
      const postData = {
        lodranAmount: lodranCash,
        lodranPeriod: lodranTimes,
        widrthdrawType: this.withdrawType
      }
      request.post('widrthdrawCash', postData)
        .then(() => {
          this.feeVisible = false
          this.orderStatus = 1
        })
        .finally(() => {
          this.flag = false
        })
    },
    goAuthentication () {
      this.$router.push({
        path: '/authentication',
        query: {
          amount: this.loanInfo.lodranAmount
        }
      })
    },
    withdraw () {
      const postData = {
        loanMoney: this.loanInfo.lodranAmount,
        loanTimers: this.loanInfo.avdrailablePeriod
      }
      this.getFeeDetail(postData, 1)
      this.feeVisible = true
    },
    onRefresh () {
      this.getOrderStatus(true)
    },
    closeWebview () {
      window.goBKBack(1)
    },
    // 跳转应用商店
    toStore (isToStore = true) {
      if (isToStore) {

      }
      this.visible = false
      localStorage.setItem('showEvaluate', true)
    }
  }
}
</script>

<style scoped lang="less">
.top-img {
  margin-left: auto;
  margin-right: auto;
}

.intro {
  line-height: 40px;
  font-size: 28px;
  color: #4d4d4d;
  text-align: center;
}

.deadline-title {
  width: 306px;
  height: 29px;
  line-height: 42px;
  margin: 23px auto 0;
  font-size: 30px;
  font-weight: 500;
  color: #4d4d4d;
  text-align: center;
  background: url(~@/assets/img/loan6-title-bg.png) no-repeat;
  background-size: cover;
}

.time {
  width: 279px;
  height: 127px;
  line-height: 127px;
  margin: 28px auto 0;
  font-size: 25px;
  color: #fff;
  text-align: center;
  background: url(~@/assets/img/loan-time-bg.png) no-repeat;
  background-size: cover;
}

.loan-status1 {
  .top-img {
    width: 336px;
    height: 412px;
    margin-top: 19px;
  }

  .intro {
    margin-top: 123px;
  }
}

.loan-status3 {
  .top-img {
    width: 512px;
    height: 482px;
    margin-top: 22px;
  }

  .intro {
    margin: 30px 60px 0;
    padding-top: 96px;
    padding-bottom: 96px;
    border: 1px solid #e5e7ea;
    border-radius: 8px;
  }

  .info {
    display: flex;
    margin-left: 132px;
    margin-right: 60px;

    .left-block {
      .circle {
        position: relative;
        padding-top: 80px;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 50%;
          width: 1px;
          height: 80px;
          transform: translateX(-50%);
          background-color: #e0e0e0;
        }

        &::after {
          content: "";
          display: block;
          border-radius: 50%;
          margin-left: auto;
          margin-right: auto;
        }

        &:first-child {
          &::after {
            width: 38px;
            height: 38px;
            border: 5px solid #2952e5;
            background-color: #fff;
          }
        }

        &:nth-child(n + 2) {
          &::after {
            width: 24px;
            height: 24px;
            background-color: #e6e5e5;
          }
        }
      }
    }

    .right-block {
      padding-top: 90px;
      margin-left: 34px;

      &>div {
        &:nth-child(n + 2) {
          margin-top: 28px;
        }

        p {
          line-height: 40px;
          font-size: 28px;
        }

        .p1 {
          color: #4d4d4d;
        }

        .p2 {
          margin-top: 4px;
          color: #ef3d53;
        }
      }
    }
  }
}

.loan-status4 {
  .top-img {
    width: 517px;
    height: 480px;
    margin-top: 30px;
  }

  .intro {
    margin-top: 54px;
  }

  .detail {
    line-height: 33px;
    margin-top: 26px;
    font-size: 24px;
    color: #4d4d4d;
    text-align: center;
  }

  .daichao {
    box-sizing: border-box;
    width: 100%;
    padding: 0 30px;
    margin: 20px 0;
  }

  .tuijian_chanpin {
    width: 570px;
    border-radius: 20px;

    .tuijian_title {
      margin-top: 40px;
      font-weight: bold;
      font-size: 38px;
    }

    .tuijian_neirong {
      display: flex;
      align-items: center;
      flex-direction: column;

      img {
        width: 150px;
        height: 150px;
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .chanpin_name {
        font-size: 26px;
        margin-bottom: 30px;
        font-weight: bold;
      }

      .youdian {
        margin-bottom: 20px;
        font-size: 26px;
        font-weight: 600;

        span {
          color: red;
          font-size: 50px;
        }
      }

      .hari {
        span {
          font-size: 30px;
        }
      }

      .OKE {
        // width: 300px;
        margin: 50px;

        a {
          color: #fff;
        }
      }
    }
  }
}

.loan-status5 {
  .info_box {
    position: relative;
    background: linear-gradient(135deg, #fd958b 0%, #fb4f5d 100%);
  }

  .top-block {
    text-align: center;
    height: 151px;
    padding: 0 34px;
    padding-top: 30px;
  }

  .p1 {
    line-height: 50px;
    font-size: 36px;
    color: #fff;
    margin-bottom: 30px;
  }

  .p2 {
    margin-top: 6px;
    font-size: 26px;
    color: #fffbf8;
  }

  .detail-block {
    width: 690px;
    height: 385px;
    margin: 15px auto 0;
  }

  .full-label,
  .installment-label {
    line-height: 88px;
    margin-top: 24px;
    height: 88px;
    background: #8b000b;
    border-radius: 10px;
    opacity: 0.95;
    text-align: center;
    font-weight: 600;
    font-size: 25px;
    color: #ffffff;
  }

  .overdue {
    font-size: 26px;
    color: #fff;
    height: 44px;
    line-height: 44px;
    background: #90040f;
    border-radius: 22px;
    width: 360px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .fenge_icon {
    height: 18px;
    width: 690px;
    margin-bottom: 20px;
  }

  .detail {
    position: absolute;
    height: 70px;
    line-height: 70px;
    text-align: center;
    background: #90040f;
    color: #fff;
    border-radius: 35px;
    bottom: -35px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 26px;
    font-weight: 600;
    padding: 0 20px;
  }

  .full-repayment-btn,
  .installment-repayment-btn {
    width: 690px;
    height: 88px;
    line-height: 88px;
    border-radius: 8px;
    margin-left: auto;
    margin-right: auto;
    font-size: 30px;
    color: #fff;
    text-align: center;
  }

  .full-repayment-btn {
    margin-top: 82px;
    background-color: #242a37;
  }

  .installment-repayment-btn {
    margin-top: 25px;
    background-color: #fb4f5d;
  }
}

.loan-status6 {
  padding-bottom: 200px;

  .status6_box {
    .jiekuanjine {
      padding-top: 30px;
      padding-bottom: 30px;
      background: url("~@/assets/img/status6_box1_bg.png");
      background-size: 100% 100%;

      .jine {
        width: 460px;
        padding: 0 20px;
        margin: 0 auto;
        text-align: center;

        div {
          font-weight: bold;
          font-size: 80px;
          color: #ffffff;
          margin-bottom: 20px;
        }

        p {
          font-weight: bold;
          font-size: 40px;
          color: #ffffff;
          margin-bottom: 20px;
        }
      }
    }

    .jine_list {
      display: grid;
      justify-content: space-between;
      grid-template-columns: repeat(auto-fill, 220px);
      padding: 0 32px;
      margin-bottom: 30px;

      .status6_circle {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 28px;
        color: #333333;
        width: 220px;
        height: 80px;
        margin-bottom: 15px;
        background: url("~@/assets/img/activate2.png");
        background-size: 100% 100%;
      }

      .status6_circle1 {
        background: url("~@/assets/img/activate1.png");
        background-size: 100% 100%;
      }

      .active {
        background: url("~@/assets/img/activate.png");
        background-size: 100% 100%;
      }
    }

    .jeikaunqixian {
      margin: 0 32px;
      height: 130px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: url("~@/assets/img/jiekuanqixian_bg.png");
      background-size: 100% 100%;
      padding: 0 60px;

      .p1 {
        font-weight: 500;
        font-size: 32px;
        color: #333333;
      }

      .p2 {
        font-weight: 400;
        font-size: 28px;
        color: #e92629;
      }
    }

    .ticket {
      position: relative;
      margin-top: 38px;
      padding-bottom: 50px;

      img {
        height: 190px;
        margin: 0 auto;
      }

      span {
        position: absolute;
        left: 70px;
        top: 60px;
        font-size: 42px;
        color: #fff;
        font-weight: 700;
      }
    }

    button {
      position: fixed;
      left: 50%;
      bottom: 100px;
      transform: translateX(-50%);
      width: 686px;
      height: 96px;
      background: #fb4f5d;
      border-radius: 16px 16px 16px 16px;
      padding: 0 50px;
      font-weight: 500;
      font-size: 32px;
      color: #ffffff;
    }
  }

  .top-img {
    width: 352px;
    height: 210px;
    margin-top: 22px;
  }

  .loan-title {
    width: 306px;
    height: 29px;
    line-height: 42px;
    margin: 30px auto 0;
    font-size: 30px;
    font-weight: 500;
    color: #4d4d4d;
    text-align: center;
    background: url(~@/assets/img/loan6-title-bg.png) no-repeat;
    background-size: cover;
  }

  .loan-amount-sort {
    display: flex;
    flex-wrap: wrap;
    padding-left: 26px;
    padding-right: 26px;
    margin-top: 29px;

    &>div {
      width: 217px;
      height: 106px;
      line-height: 106px;
      font-size: 30px;
      color: #4d4d4d;
      text-align: center;
      background-repeat: no-repeat;
      background-size: cover;

      &:not(:nth-child(3n + 1)) {
        margin-left: 23px;
      }

      &:nth-child(n + 4) {
        margin-top: 12px;
      }

      &.active {
        color: #fff;
        background-image: url(~@/assets/img/can-loan-bg-active.png);
      }
    }

    .can-loan {
      background-image: url(~@/assets/img/can-loan-bg.png);
    }

    .no-loan {
      background-image: url(~@/assets/img/no-loan-bg.png);
    }
  }

  .submit {
    .submit-btn;
    font-size: 28px;
    position: fixed;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
  }

  .rise-limit-title {
    .dialog-title;
    padding-left: 24px;
    text-align: left;
  }

  .tip-title {
    margin-top: 32px;
    font-size: 30px;
    color: #333;
    text-align: center;
  }

  .tip {
    padding-left: 49px;
    padding-right: 49px;
    margin-top: 16px;
    font-size: 26px;
    color: #999;
    text-align: center;
  }

  .confirm-btn {
    .submit-btn;
    margin-top: 41px;
    margin-bottom: 11px;
  }
}

.loan-status8 {
  .top-img {
    width: 100%;
    height: 424px;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .detail {
    margin: 18px 78px 0;
    font-size: 24px;
    color: #999;
    text-align: center;
  }

  .modify-btn {
    .submit-btn;
    margin-top: 200px;
  }
}

.loan-status9 {
  .top-img {
    width: 260px;
    height: 348px;
    margin-top: 60px;
  }

  .intro {
    margin-top: 54px;
  }

  .detail {
    margin-top: 26px;
    font-size: 24px;
    color: #4d4d4d;
    text-align: center;
  }

  .modify-btn {
    .submit-btn;
    margin-top: 116px;
  }

  .cancel-loan-dialog {
    border-radius: 32px;
    background-color: #fff;
    overflow: visible;
  }

  .cancel-loan-img {
    width: 383px;
    height: 324px;
    margin: -59px auto 0;
  }

  .cancel-loan-dialog-title {
    line-height: 52px;
    margin-top: 29px;
    font-size: 37px;
    color: #894322;
    text-align: center;
  }

  .tip {
    margin: 30px 44px 0;
    font-size: 26px;
    color: #894322;
    text-align: center;
  }

  .confirm-btn {
    width: 427px;
    height: 99px;
    padding: 0 0 22px;
    margin: 33px auto 36px;
    font-size: 29px;
    font-weight: 500;
    color: #231815;
    background: url(~@/assets/img/cancel-loan-confirm-btn.png) no-repeat;
    background-size: cover;
  }
}

.loan-status12 {
  padding: 12px 30px 0;

  .withdraw-btn {
    .submit-btn;
    margin-top: 300px;
    margin-bottom: 98px;
  }
}

.fee-title {
  .dialog-title;
}

.fee-detail {
  margin: 32px 25px 38px;
  margin-top: 60px;

  &>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 103px;
    line-height: 103px;
    padding-left: 24px;
    padding-right: 24px;

    p {
      font-weight: 500;
      font-size: 28px;
      color: #333333;
    }
  }

  .interest {
    position: relative;
    align-items: start;

    height: 120px;
    line-height: 60px;

    span:nth-child(1) {
      text-decoration: line-through;
      margin-right: 10px;
    }

    div {
      position: absolute;
      top: 60px;
    }
  }

  .describe {
    margin-bottom: -5px;
    font-size: 26px;
    color: #ff8300;
    line-height: 28px;
  }
}

.footer-btn {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 51px;

  button {
    line-height: 85px;
    padding: 0 0 56px;
    font-size: 32px;
    color: #fff;
  }

  .cancel-btn {
    width: 300px;
    height: 85px;
    background: #aaaaaa;
    box-shadow: 0px 4px 4px 0px rgba(0, 14, 91, 0.2);
    border-radius: 16px 16px 16px 16px;
  }

  .ok-btn {
    width: 300px;
    height: 85px;
    background: #fb4f5d;
    box-shadow: 0px 4px 4px 0px rgba(0, 14, 91, 0.2);
    border-radius: 16px 16px 16px 16px;
  }
}
</style>
